import { Injectable, effect, signal } from '@angular/core';
import { Filmstrip, Module } from '@ay-gosu-party/modules';
import { getModule } from '@ay-gosu-party/modules/modules';
import { recalcFilmstripIndex } from '@ay-gosu-party/modules/utils/recalc-filmstrip-index';
import {
  CurrentChangeDto,
  MobileEventModel,
  PlayerModel,
} from '@ay-gosu-party/server-shared';
import { asyncJob } from '@ay-gosu/ui/common/async-job';
import { Subscription } from 'rxjs';
import { MobileLoginService } from './login.service';

@Injectable({ providedIn: 'root' })
export class MobileService {
  public eventId = this._loginService.eventId;

  public event = asyncJob((eventId) => {
    if (!eventId) return null;

    return MobileEventModel.fetch(eventId);
  }, this.eventId);

  public modules = asyncJob((event) => {
    if (!event) return [];

    const modules: Module[] = [];
    for (const moduleRaw of event.modules) {
      const Module = getModule(moduleRaw.type);
      if (!Module) {
        console.error('No module found for type', moduleRaw.type);
        continue;
      }

      const module = new Module(moduleRaw);
      modules.push(module);
    }

    recalcFilmstripIndex(modules);
    return modules;
  }, this.event);

  public isLoading = signal(true);

  public current = signal<{ module: Module; filmstrip: Filmstrip } | null>(
    null,
  );

  public subscription: Subscription | null = null;

  public constructor(private readonly _loginService: MobileLoginService) {
    this._updateCurrent();
  }

  public async refreshCurrent() {
    const eventId = this.eventId();
    if (!eventId) return;
    const current = await PlayerModel.fetchCurrent(eventId);
    this._onCurrentChanged(current, this.modules.data());
  }

  private _updateCurrent() {
    effect(() => {
      const eventId = this.eventId();
      const isLogged = this._loginService.isLogged();
      const isJoined = this._loginService.isJoined();
      const modules = this.modules.data();

      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = null;
      }

      if (!isLogged || !eventId || !isJoined || !modules) return;

      this.subscription = PlayerModel.listenCurrentChanged(eventId).subscribe(
        (current) => this._onCurrentChanged(current, modules),
      );
    });
  }

  private _onCurrentChanged(current: CurrentChangeDto, modules: Module[]) {
    this.isLoading.set(false);

    if (current.moduleUuid === undefined) {
      return;
    }

    for (const module of modules) {
      for (const filmstrip of module.filmstrips) {
        if (filmstrip.index === current.filmstripIndex) {
          this.current.set({ module, filmstrip });
          return;
        }
      }
    }
  }
}
